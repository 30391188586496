import React from "react"
import styled from "styled-components"
import { device } from "../../constants/breakpoints"
import TrattamentoCostiTable from "./TrattamentoCostiTable"

const Container = styled.div`
  padding: 50px;
  padding-bottom: 120px;
  @media ${device.tablet} {
    padding: 0px;
    padding-top: 20px;
  }
`

const Wrapper = styled.div`
  margin: auto;
  max-width: 1400px;
`

export default function TrattamentoCostiContainer({ trattamento }) {
  return (
    <Wrapper>
      <Container>
        <TrattamentoCostiTable trattamento={trattamento} />
      </Container>
    </Wrapper>
  )
}
