import { Link } from "gatsby"
import React, { useRef, useState } from "react"
import { Table } from "react-bootstrap"
import styled from "styled-components"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import GlobalIcon from "../Atoms/GlobalIcon"
import { navigate } from "gatsby"
import {
  NoDesktop,
  GlobalRow,
  NoMobile,
} from "../ChirurghiSearch/styles/ChirurghiSearch.styled"
import ContactModal from "../ContactFormModal/ContactFormModal"
import { useDispatch, useSelector } from "react-redux"
import { selectTrattamentoPageCosti } from "../../redux/trattamentoPageSlice"
import axios from "axios"
import { GET_CHIRURGO } from "../../constants/api"
import { openModal, openModalWithTrattamento } from "../../redux/layoutSlice"
import navigateToChirurgo from "../../functions/navigateToChirurgo"
import { formatToCurrency } from "../../functions/formatPrice"
import { device } from "../../constants/breakpoints"

const Dottor = styled.p`
  cursor: pointer;
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 0;
  transition: color 0.5s ease;
  :hover {
    color: ${({ theme }) => theme.blue};
  }
  @media ${device.tablet} {
    font-size: 14px;
    color: ${theme => theme.lightblue};
    text-decoration: underline;
  }
`

const ContactContainer = styled(GlobalRow)`
  cursor: pointer;
`
const Promotion = styled.td`
  border: none;
  background-color: #337ab7;
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  background-color: ${props =>
    props.promotion
      ? props => props.theme.lightblue
      : "transparent"} !important;
`

const DottorLocations = styled.p`
  font-size: 12px;
  margin-bottom: 0;
  color: ${props => props.theme.darkblue};
  @media ${device.tablet} {
    font-weight: 500;
  }
`
const Promo = styled.span`
  font-size: 14px;
  color: ${props => props.theme.lightblue} !important;
`

const Styledtd = styled.td`
  vertical-align: middle;
  color: ${props => props.theme.darkblue};
  font-weight: 500;

  @media ${device.tablet} {
    vertical-align: top;
    font-size: 12px;
  }
`

const StyledTableRow = styled.tr`
  border-bottom: 15px solid white !important;
  background-color: ${props => props.theme.lightocean} !important;

  @media ${device.tablet} {
    border-bottom: 1px solid lightgray !important;
    background-color: white !important;
    & > ${Styledtd}:nth-child(3) {
      ::before {
        content: "da ";
      }
    }
    & > ${Styledtd}:nth-child(4) {
      ::before {
        content: "fino a ";
      }
    }
    & > ${Styledtd}:nth-child(5) {
      display: none;
    }
    & > ${Styledtd}:nth-child(6) {
      display: none;
    }
  }
`

const StyledTHead = styled.thead`
  @media ${device.tablet} {
    display: none;
  }
`

const Title = styled.p`
  margin-bottom: 5px;
  font-weight: 400;
  color: ${({ theme }) => theme.blue};
  font-size: 13px;
`

const Text = styled.p`
  font-size: 13px;
  margin-bottom: 10px;
`

const LeftCol = styled.div`
  flex: 1;
`

const RightCol = styled.div`
  text-align: right;
`

const StyleTh = styled.th`
  @media (max-width: 1024px) {
    font-size: 14px;
  }
`

const StyledCostoCard = styled.div`
  margin: 10px;
  border-bottom: 1px solid lightgray;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
`

const goToChirurgoAsync = async id => {
  const { data } = await axios.get(GET_CHIRURGO(id))
  navigateToChirurgo(data[0])
}

const DottorColumn = ({ id, chirurgo, locations }) => {
  return (
    <td>
      <Dottor onClick={() => goToChirurgoAsync(id)}>{chirurgo}</Dottor>
      <DottorLocations>{locations}&nbsp;</DottorLocations>
    </td>
  )
}

const CostoCard = ({
  nome,
  locations,
  prezzo_max,
  prezzo_min,
  finanziamento,
  promozione,
  id_chi,
}) => {
  return (
    <StyledCostoCard>
      <LeftCol>
        <Title onClick={() => goToChirurgoAsync(id_chi)}>{nome}</Title>
        <div style={{ height: 10 }} />
        <DottorLocations>{locations}</DottorLocations>
      </LeftCol>
      <RightCol>
        <DottorLocations>
          da {formatToCurrency(prezzo_min)} a {formatToCurrency(prezzo_max)}
        </DottorLocations>
        <div style={{ height: 10 }} />
        {finanziamento != "" || promozione != 0 ? (
          finanziamento != "" ? (
            <GlobalRow>
              <GlobalIcon img={require("../../images/euro-light.png")} />{" "}
              <Promo style={{ fontWeight: 600 }}>finanziamento</Promo>{" "}
            </GlobalRow>
          ) : (
            <GlobalRow>
              <GlobalIcon img={require("../../images/promo-doouble.png")} />{" "}
              <Promo style={{ fontWeight: 600 }}>promozione</Promo>{" "}
            </GlobalRow>
          )
        ) : null}
      </RightCol>
    </StyledCostoCard>
  )
}

export default function TrattamentoCostiTable({ trattamento }) {
  const costi = useSelector(selectTrattamentoPageCosti)
  const { width } = useWindowDimensions()
  const ref = useRef(null)
  const dispatch = useDispatch()
  return (
    <>
      <NoMobile>
        <Table borderless ref={ref}>
          <StyledTHead>
            <tr>
              <StyleTh style={{ width: "3%" }}></StyleTh>
              <StyleTh style={{ width: "35%" }}></StyleTh>
              <StyleTh style={{ width: "10%" }}>Da</StyleTh>
              <StyleTh style={{ width: "10%" }}>Fino a</StyleTh>
              <StyleTh style={{ width: "15%" }}>Finanziamenti</StyleTh>
              <StyleTh style={{ width: "16%" }}>
                {width < 1387 ? (
                  <>
                    <GlobalIcon img={require("../../images/offer.png")} />
                    <Promo>In promozione</Promo> <br /> Preventivo
                  </>
                ) : (
                  <>
                    {" "}
                    Preventivo
                    <GlobalIcon
                      style={{ marginLeft: 10 }}
                      img={require("../../images/offer.png")}
                    />
                    <Promo>In promozione</Promo>
                  </>
                )}
              </StyleTh>
            </tr>
          </StyledTHead>
          <tbody>
            {costi.map(c => (
              <StyledTableRow key={c.id_sys}  ref={ref}>
                {c.promozione != 0 ? (
                  <Promotion
                    img={require("../../images/offer_white.png")}
                    promotion={c.promozione}
                  />
                ) : (
                  <Promotion promotion={c.promozione} />
                )}
                <DottorColumn
                  id={c.id_chi}
                  chirurgo={c.nome}
                  locations={c.list_provincie}
                />
                <Styledtd>{formatToCurrency(c.prezzo_min)}</Styledtd>
                <Styledtd finanziamento={c.finanziamento}>
                  {formatToCurrency(c.prezzo_max)}
                </Styledtd>
                <Styledtd>
                  {c.finanziamento != "" ? (
                    <GlobalRow>
                      <GlobalIcon
                        style={{ height: 30 }}
                        img={require("../../images/visto.png")}
                      />{" "}
                      Tasso zero
                    </GlobalRow>
                  ) : (
                    "Non disponibile"
                  )}
                </Styledtd>
                <Styledtd>
                  <ContactContainer onClick={() =>   dispatch(
                    openModal(c )
                  )}>
                    <GlobalIcon img={require("../../images/fold.png")} /> Chiedi
                    un preventivo
                  </ContactContainer>
                </Styledtd>
              </StyledTableRow>
            ))}
          </tbody>
        </Table>
      </NoMobile>
      <NoDesktop>
        {costi.map(c => {
          return <CostoCard {...c} />
        })}
      </NoDesktop>
    </>
  )
}
