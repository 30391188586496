import { navigate } from "gatsby"
export default (comune, trattamento, finan, video, checked) => {
 if(trattamento) {
  return navigate(`/chirurghi-estetici/${trattamento}/${comune}`, {
   state: {finan, video,checked}
  })
 }
  return navigate(`/chirurghi-estetici/${comune}`,{
   state: {finan, video,checked}

  })
}
