import styled from "styled-components"

import React from "react"
import { useSelector } from "react-redux"
import {
  selectTrattamentoArticles,
  selectTrattamentoPage,
  selectTrattamentoPrePost,
  selectTrattamentoReviews,
  selectTrattamentoVideos,
} from "../../redux/trattamentoPageSlice"
import { navigate } from "gatsby"
import { device } from "../../constants/breakpoints"
import navigateToTrattamento from "../../functions/navigateToTrattamento"
import navigateToChirurghi from "../../functions/navigateToChirurghi"
import getClosestCity from "../../functions/getClosestCity"
import formatTrattamento from "../../functions/formatTrattamento"
import { selectPosition } from "../../redux/menuSlice"
import { selectLayoutComuni } from "../../redux/layoutSlice"
const Voce = styled.p`
  margin-bottom: 0;
  flex: 1;
  color: white !important;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: ${({ active }) => (active ? "underline" : "none")};

  &:hover {
    color: white;
    text-decoration: underline;
  }

  @media ${device.laptop} {
    font-size: 15px;
  }

  @media ${device.tablet} {
    font-size: 12px;
    padding-right: 40px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;

  background: ${props => props.theme.darkocean};
  padding: 20px;
  padding-right: 10%;
  padding-left: 10%;
  &::-webkit-scrollbar {
    display: none;
  }
  @media ${device.tablet} {
    white-space: nowrap;
    overflow: scroll;
    z-index: 100;
  }
`

const ArrowLeftWrapper = styled.div`
  position: absolute;
  padding-right: 5px;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: ${props => props.theme.darkocean};
  top: 240px;
  left: 0px;
  transition: opacity 0.4s ease;
  opacity: ${({ visible }) => (visible ? 1 : 0)};

  @media ${device.tablet} {
    display: none;
  }
`

const ArrowLeft = styled.div`
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  width: 10px;
  height: 10px;
  transform: rotate(135deg);
`

const ArrowRightWrapper = styled.div`
  position: absolute;
  padding-right: 15px;
  padding-left: 38px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: ${props => props.theme.darkocean};
  top: 590px;
  right: 0px;
  transition: opacity 0.4s ease;
  opacity: ${({ visible }) => (visible ? 1 : 0)};

  @media (min-width: 768px) {
    display: none;
  }
`

const ArrowRight = styled.div`
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  width: 10px;
  height: 10px;
  transform: rotate(-45deg);
`

export default function TrattamentoMenu({ trattamento, active }) {
  const scrollRef = React.useRef()
  const [arrowLeft, setArrowLeftVisible] = React.useState(false)
  const [arrowRight, setArrowRightVisbile] = React.useState(true)
  const prepost = useSelector(selectTrattamentoPrePost)
  const video = useSelector(selectTrattamentoVideos)
  const reviews = useSelector(selectTrattamentoReviews)
  const articles = useSelector(selectTrattamentoArticles)
  const position = useSelector(selectPosition)
  const comuni = useSelector(selectLayoutComuni)
  const cities = getClosestCity(position,comuni)
  const navigateTo = to => {
    navigateToTrattamento(trattamento, to)
  }

  const handleScroll = event => {
    if (scrollRef.current.scrollLeft > 0) {
      setArrowLeftVisible(true)
      setArrowRightVisbile(false)
    } else {
      setArrowLeftVisible(false)
      setArrowRightVisbile(true)
    }
  }

  const scrollToStart = () => {
    scrollRef.current.scroll({ left: 0, behavior: "smooth" })
  }

  const scrollToEnd = () => {
    scrollRef.current.scroll({
      left: scrollRef.current.scrollWidth,
      behavior: "smooth",
    })
  }

  React.useEffect(() => {
    if (!scrollRef.current) return
    scrollRef.current.addEventListener("scroll", handleScroll)

    return () => scrollRef?.current?.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <Container ref={scrollRef}>
      <ArrowRightWrapper onClick={scrollToEnd} visible={arrowRight}>
        <ArrowRight />
      </ArrowRightWrapper>
      <ArrowLeftWrapper onClick={scrollToStart} visible={arrowLeft}>
        <ArrowLeft />
      </ArrowLeftWrapper>

      <Voce onClick={() => navigateTo("/")} active={active == 0}>
        {"Informazioni"}
      </Voce>
      {prepost.length > 0 && (
        <Voce onClick={() => navigateTo("/prepost/")} active={active == 1}>
          {"Pre-post"}
        </Voce>
      )}
      {video.length > 0 && (
        <Voce onClick={() => navigateTo("/video/")} active={active == 2}>
          {"Video"}
        </Voce>
      )}
      <Voce onClick={() => navigateToChirurghi(getClosestCity(position,comuni), formatTrattamento(trattamento.trattamento))} active={active == 3}>
        {"Chirurghi"}
      </Voce>
      <Voce onClick={() => navigateTo("/costi/")} active={active == 4}>
        {"Costi"}
      </Voce>
      {reviews.length > 0 && (
        <Voce onClick={() => navigateTo("/esperienze/")} active={active == 5}>
          {"Esperienze"}
        </Voce>
      )}
      {articles.length > 0 && (
        <Voce onClick={() => navigateTo("/articoli/")} active={active == 6}>
          {"Articoli"}
        </Voce>
      )}
    </Container>
  )
}
