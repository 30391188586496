import styled from "styled-components"
import { breakpoints, device } from "../../../constants/breakpoints"

export const GlobalRow = styled.div`
  display: flex !important;
  flex-direction: row;
  align-content: center;
  vertical-align: center;
  align-items: center;
`

export const Wrapper = styled.div`
  background: #fafafa;
`
export const Container = styled.div`
  padding-top: 30px;
  padding-bottom: 150px;
  max-width: 1440px;
  margin: auto;
  @media ${device.tablet} {
    padding-top: 15px;
    flex-direction: column;
  }
`

export const MobileCardsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CardsContainer = styled.div`
  width: 80%;
  @media ${device.tablet} {
    display: none;
  }
`

export const NoDesktop = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`

export const NoMobile = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`

export const CardsTitle = styled.p`
  color: ${({ theme }) => theme.blackblue};
  font-size: 28px;
  line-height: 35px;
  font-weight: 600;
  margin: 50px;
  margin-top: 6px;
  text-align: center;
`

export const CardsSubTitle = styled.p`
  color: ${({ theme }) => theme.lightblue};
  font-size: 22px;
  text-align: center;
  margin-bottom: 0;
`
