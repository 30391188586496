import { Markup } from "interweave"
import React from "react"
import HeroSection from "../../components/HeroSection/HeroSection"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import baseImg from "../../images/hero.png"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "../../redux/userSlice"
import { breakpoints } from "../../constants/breakpoints"

export default function TrattamentoHeroContainer({ tra }) {
  const {
    foto_banner_url,
    foto_banner_mobile_url,
    descrizione_banner,
    descrizione_banner_mobile,
    trattamento,
  } = tra
  const { width } = useWindowDimensions()
  const isMobile = width < breakpoints.tabletSm
  const [descrizione, image] = [
    isMobile ? descrizione_banner_mobile : descrizione_banner,
    isMobile ? foto_banner_mobile_url : foto_banner_url,
  ]
  const user = useSelector(selectCurrentUser)
  return (
    <HeroSection.Trattamenti img={image || baseImg}>
      <HeroSection.InfoToast />
      <HeroSection.SuccessToast />
      <HeroSection.FeatureTrattamenti style={{ marginTop: 0 }}>
        {trattamento}
      </HeroSection.FeatureTrattamenti>
      <HeroSection.Summary>
        <Markup content={descrizione} />
      </HeroSection.Summary>
      {user?.user_email && <HeroSection.PrefInnerItemHeart pref={tra} />}
    </HeroSection.Trattamenti>
  )
}
